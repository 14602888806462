import { Card, LoadingHeading, LoadingParagraph } from "@kaizen/components"
import { FC } from "react"

const FeedLoadingTile: FC = () => {
  return (
    <Card classNameOverride="p-24 shadow-none">
      <div className="flex flex-col gap-16">
        <LoadingHeading variant="heading-3" classNameOverride="max-w-[400px]" />
        <div>
          <LoadingParagraph classNameOverride="max-w-[90px]" />
          <LoadingParagraph classNameOverride="max-w-[224px]" />
          <LoadingParagraph classNameOverride="max-w-[224px]" />
        </div>
      </div>
    </Card>
  )
}

export const FeedLoading: FC = () => {
  return (
    <div className="flex flex-col gap-16">
      <FeedLoadingTile />
      <FeedLoadingTile />
      <FeedLoadingTile />
      <FeedLoadingTile />
    </div>
  )
}
